import { useEffect, useState } from "react";
import { fetchAllTransactions } from "../../FireBase/adminServices";
import { FaSearch } from "react-icons/fa";
import { fetchAllLogs } from "../../FireBase/adminServices";
import "../AdminDashboard/Pages/pages.css";
import LogsModal from "./LogsModal";

const Logs = () => {
  const [userData, setUserData] = useState(null);
  const [search, setSearch] = useState("");
  const [selectedLog, setSelectedLog] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchAllLogs();
        setUserData(data);
        console.log(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleViewClick = (log) => {
    setSelectedLog(log);
  };

  const handleCloseModal = () => {
    setSelectedLog(null);
  };

  let count = 1;
  return userData != null ? (
    <main className="a_main-container">
      <div className="pt">
        <form className="search-bar">
          <FaSearch className="icon" />
          <input
            type="text"
            placeholder="Search Here.."
            onChange={(e) => setSearch(e.target.value.toLowerCase())}
          ></input>
        </form>
      </div>
      <div className="a_table">
        <table id="users">
          <thead className="tablehead">
            <tr>
              <th>ID</th>
              <th>Event</th>
              <th>Profile Name</th>
              <th>Time</th>
              <th>IP Address</th>
              <th>Location</th>
              <th>View More</th>
            </tr>
          </thead>
          <tbody>
            {userData
              .filter((item) => {
                try {
                  return search.toLowerCase() === ""
                    ? item
                    : item.EVENT.toLowerCase().includes(search) || 
                    item.GEO_INFORMATION.REGION.toLowerCase().includes(search) ||
                    item.GEO_INFORMATION.COUNTRY.toLowerCase().includes(search) ||
                    item.GEO_INFORMATION.CITY.toLowerCase().includes(search)
                } catch (error) {
                  return true;
                }
              })
              .map((item) => (
                <tr key={item.id}>
                  <td>{count++}</td>
                  <td>{item.EVENT}</td>
                  <td>{item.USER_INFORMATION.PROFILE_NAME}</td>
                  <td>{item.USER_INFORMATION.DATE_TIME}</td>
                  <td>{item.USER_INFORMATION.IP_ADDRESS}</td>
                  <td>
                    {item.GEO_INFORMATION.CITY +
                      ", " +
                      item.GEO_INFORMATION.REGION +
                      ", " +
                      item.GEO_INFORMATION.COUNTRY}
                  </td>
                  <td className="report-button">
                    <button onClick={() => handleViewClick(item)}>View</button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      {selectedLog && (
        <LogsModal log={selectedLog} onClose={handleCloseModal} />
      )}
    </main>
  ) : (
    <div>
      <span className="loader"></span>
    </div>
  );
};

export { Logs };