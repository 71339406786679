import {
  browserSessionPersistence,
  onAuthStateChanged,
  setPersistence,
} from "firebase/auth";


import {
  app,
  getAuth,
  signInWithEmailAndPassword,
  signOut,
  getFirestore,
} from "./firebase.js";


import { adminLogin } from "./adminServices.js";

const auth = getAuth(app);
const firestore = getFirestore(app);


const addAuthChangeListener = (onChange) => {
  return onAuthStateChanged(auth, onChange);
};


// Function to login with email and password
const loginWithEmailAndPassword = async (email, password) => {
  try {

    await auth.setPersistence(
       browserSessionPersistence
    );

    // Sign in with the email and password
    const userCredential = await signInWithEmailAndPassword(
      auth,
      email+"@admin.com",
      password
    );

    // Retrieve the current user
    const currentUser = userCredential.user;

    const adminCredentials = await adminLogin()
    
    if(currentUser.uid === adminCredentials.uid)
    {
      console.log("successfull login")
      return { status: "success", currentUser };
    }


    else
    {
      await logout()
      console.log('non privilaged user login attempt.')
      return { status: "error", message: "Invalid privilage. Try again" };
    }


    } 
    catch (error) {
      switch (error.code) {
        case "auth/user-not-found":
          return { status: "error", message: "User not found" };
        case "auth/wrong-password":
          return { status: "error", message: "Incorrect password" };
        case "auth/invalid-credential":
          return { status: "error", message: "Invalid credentials. Try again" };
        case "auth/too-many-requests":
          return {
            status: "error",
            message:
              "Too many login attempts. Please try again later or reset your password.",
          };
        default:
          return { status: "error", message: "An unexpected error occurred" };
      }
  }
};



// Function to logout the current user
const logout = async () => {
  signOut(auth);
};


function getCurrentUser() {
  return new Promise((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged(
      (user) => {
        unsubscribe();
        resolve(user);
      },
      reject,
      // This line is added to handle the case where there is no change in the authentication state
      () => {
        unsubscribe();
        resolve(null); // Resolve with null to indicate no user is signed in
      }
    );
  });
}

export {
  auth,
  firestore,
  loginWithEmailAndPassword,
  logout,
  setPersistence,
  getCurrentUser,
  addAuthChangeListener,

};
