import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import Login from "./Components/Admin/AdminLogin/Admin"

import { createBrowserRouter } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));

const router = createBrowserRouter([
  {}
])


root.render(
  <React.StrictMode>
    <Login/>
  </React.StrictMode>
);
