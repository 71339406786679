import {
  getFirestore,
  collection,
  addDoc,
  setDoc,
  getDocs,
  orderBy,
  query,
  app,
  where,
  deleteDoc,
  updateDoc
} from "./firebase.js";
import EventEmitter from "events";
const firestore = getFirestore(app);

// Generates a new coupon with specified details
const generateVoucher1 = async (data) => {
  const couponDetails = {
    code: data.code,
    validFrom: data.validityStart,
    validTill: data.validityEnd,
    limit: 1,
    redeemed: 0,
    credit: data.credit,
    groupId: data.groupId,
    groupName:data.groupName
  };

  try {
    const couponsCollectionRef = collection(firestore, "coupon");
    // Query to check if the coupon code already exists
    const querySnapshot = await getDocs(
      query(couponsCollectionRef, where("code", "==", data.code))
    );

    console.log("reached here");
    if (!querySnapshot.empty) {
      // Coupon code already exists, show alert message
      alert("Coupon code already exists");
      return;
    }

    // Coupon code doesn't exist, add it to Firestore
    await addDoc(couponsCollectionRef, couponDetails);
    console.log("Data written to Firestore successfully!");
  } catch (error) {
    console.error("Error writing document: ", error);
  }
};


const prepareBulkVouchers = async (quantity) => {
  const voucherCodes = [];
  
  console.log("randomly generating")
  const generateRandomLetters = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let result = '';
    for (let i = 0; i < 4; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  };


  const isGroupIdUnique = async (groupId) => {
    const couponsCollectionRef = collection(firestore,"coupon")
    const q = query(couponsCollectionRef, where("groupId", "==", groupId));
    const querySnapshot = await getDocs(q);
    return querySnapshot.empty; // Return true if groupId doesn't exist
  };

  // Generate unique groupId
  let groupId = generateRandomLetters();
  while (!(await isGroupIdUnique(groupId))) {
    console.log(`GroupId ${groupId} already exists, generating a new one.`);
    groupId = generateRandomLetters(); // Regenerate if it exists
  }

  // Generate the vouchers
  for (let i = 0; i < quantity; i++) {
    let newVoucher;
    do {
      newVoucher = groupId + '-' + generateRandomLetters() + '-' + generateRandomLetters();
    } while (voucherCodes.includes(newVoucher)); // Ensure no duplicates
    voucherCodes.push(newVoucher);
  }

  console.log({groupId:groupId, codes:voucherCodes})
  return {groupId:groupId, codes:voucherCodes};

};


const generateBulkVouchers = async (voucherData) => {
  const codeData = await prepareBulkVouchers(voucherData.quantity)

  for(let i = 0; i<voucherData.quantity; i++)
    await generateVoucher1({groupId:codeData.groupId, code: codeData.codes[i], ...voucherData})


  console.log("Generated All vouchers")
  return true

}


const updateCouponsWithGroup = async () => {
  try {
    const couponsCollectionRef = collection(firestore, "coupon");
    const querySnapshot = await getDocs(couponsCollectionRef);

    querySnapshot.forEach(async (doc) => {
      const docData = doc.data(); // Get the existing document data

      // Update the document with the new 'group' field while preserving other fields
      await updateDoc(doc.ref, {
        ...docData,
        groupId: "BRNC",
        groupName: "BranchSelector"
      });
    });

    console.log("Group field added to all coupons successfully!");
  } catch (error) {
    console.error("Error updating documents: ", error);
  }
};

// Function to retrieve all coupons and return them in JSON form
const getAllCoupons = async () => {
  try {
    const couponsCollectionRef = collection(firestore, "coupon");
    const querySnapshot = await getDocs(couponsCollectionRef);

    const coupons = [];
    querySnapshot.forEach((doc) => {
      coupons.push({ id: doc.id, ...doc.data() });
    });

    return coupons;
  } catch (error) {
    console.error("Error retrieving coupons: ", error);
    return [];
  }
};

// Function to delete a coupon given its coupon code
const deleteCoupon = async (code) => {
  try {
    const couponsCollectionRef = collection(firestore, "coupon");
    const querySnapshot = await getDocs(
      query(couponsCollectionRef, where("code", "==", code))
    );

    if (querySnapshot.empty) {
      console.log("Coupon not found.");
      return;
    }

    querySnapshot.forEach(async (doc) => {
      await deleteDoc(doc.ref);
      console.log("Coupon deleted successfully.");
    });
  } catch (error) {
    console.error("Error deleting coupon: ", error);
  }
};


const deleteVoucherGroup = async (groupId) => {
  try {
    const couponsCollectionRef = collection(firestore, "coupon");
    const querySnapshot = await getDocs(
      query(couponsCollectionRef, where("groupId", "==", groupId))
    );

    if (querySnapshot.empty) {
      console.log("Coupon not found.");
      return;
    }

    querySnapshot.forEach(async (doc) => {
      await deleteDoc(doc.ref);
      console.log("Coupon deleted successfully.");
    });
  } catch (error) {
    console.error("Error deleting coupon: ", error);
  }
};

// Generates a new coupon with specified details
const generateVoucher = async (data) => {
  const voucherDetails = {
    code: data.code,
    discountPercentage: data.discount_percentage,
    validFrom: data.validityStart,
    validTill: data.validityEnd,
    limit: data.quantity,
    redeemed: 0,
  };

  try {
    const vouchersCollectionRef = collection(firestore, "vouchers");
    // Query to check if the voucher code already exists
    const querySnapshot = await getDocs(
      query(vouchersCollectionRef, where("code", "==", data.code))
    );

    if (!querySnapshot.empty) {
      // vouccher code already exists, show alert message
      alert("Voucher code already exists");
      return;
    }

    // voucher code doesn't exist, add it to Firestore
    await addDoc(vouchersCollectionRef, voucherDetails);
    console.log("reached here");
    console.log("Data written to Firestore successfully!");
  } catch (error) {
    console.error("Error writing document: ", error);
  }
};

// Function to retrieve all coupons and return them in JSON form
const getAllVouchers = async () => {
  try {
    const vouchersCollectionRef = collection(firestore, "vouchers");
    const querySnapshot = await getDocs(vouchersCollectionRef);

    const vouchers = [];
    querySnapshot.forEach((doc) => {
      vouchers.push({ id: doc.id, ...doc.data() });
    });

    return vouchers;
  } catch (error) {
    console.error("Error retrieving vouchers: ", error);
    return [];
  }
};

// Function to delete a coupon given its coupon code
const deleteVoucher = async (code) => {
  try {
    const vouchersCollectionRef = collection(firestore, "vouchers");
    const querySnapshot = await getDocs(
      query(vouchersCollectionRef, where("code", "==", code))
    );

    if (querySnapshot.empty) {
      console.log("Voucher not found.");
      return;
    }

    querySnapshot.forEach(async (doc) => {
      await deleteDoc(doc.ref);
      console.log("Voucher deleted successfully.");
    });
  } catch (error) {
    console.error("Error deleting voucher: ", error);
  }
};

// Fetches all users and counts the number of free and paid users
// Fetches all users and counts the number of free and paid users
const fetchAllUsers = async () => {
  let paidUsers = 0;
  let freeUsers = 0;
  let testTaken = 0; // Initialize test taken count

  try {
    const q = query(collection(firestore, "users"));
    const querySnapshot = await getDocs(q);
    const docsList = [];
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      
      if (data["accountType"] === "free") freeUsers++;
      else if (data["accountType"] === "paid") paidUsers++;

      // Count tests taken by each user, ensuring it's defined
      testTaken += data["testsTaken"] ? data["testsTaken"] : 0;
      docsList.push(data);
    });
    return {
      usersList: docsList,
      freeUsers: freeUsers,
      paidUsers: paidUsers,
      testTaken: testTaken,
    }; // Return test taken count
  } catch (error) {
    console.log(error);
  }
};

const fetchAllAppointments = async () => {
  try {
    const q = query(collection(firestore, "appointments"));
    const querySnapshot = await getDocs(q);
    const upcomingAppointments = [];
    const completedAppointments = [];

    querySnapshot.forEach((doc) => {
      const data = doc.data();
      const selectedTime = new Date(data.selectedDate);

      if (selectedTime > new Date()) {
        upcomingAppointments.push(data);
      } else {
        completedAppointments.push(data);
      }
    });

    return {
      upcoming: upcomingAppointments,
      completed: completedAppointments
    };
  } catch (error) {
    console.log(error);
    return { error: "Failed to fetch appointments" };
  }
};

const fetchAllLogs = async () => {
  try {
    const q = query(
      collection(firestore, "logs"),
      orderBy("TIMESTAMP", "desc")  // Sort by timestamp, descending order
    );
    
    const querySnapshot = await getDocs(q);
    const logs = [];

    querySnapshot.forEach((doc) => {
      const data = doc.data();
      logs.push({
        id: doc.id,
        ...data
      });
    });

    return logs;
  } catch (error) {
    console.error("Error fetching logs:", error);
    return { error: "Failed to fetch logs" };
  }
};

const fetchAllTransactions = async () => {

  try {
    const q = query(collection(firestore, "transactions"));
    const querySnapshot = await getDocs(q);
    const docsList = [];
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      docsList.push(data);
    });
    return docsList
  } catch (error) {
    console.log(error);
  }
};


const fetchUserTests = async (updateCallback) => {
  function convertToUnixTime(dateString) {
    const [datePart, timePart] = dateString.split(', ');
    const [day, month, year] = datePart.split('/').map(Number);
    const [time, period] = timePart.split(' ');
    const [hour, minute] = time.split(':').map(Number);

    let hours = period === 'pm' && hour !== 12 ? hour + 12 : hour;
    if (period === 'am' && hour === 12) hours = 0;

    const date = new Date(year + 2000, month - 1, day, hours, minute);
    return Math.floor(date.getTime() / 1000);
  }

  const eventEmitter = new EventEmitter();

  try {
    const usersCollection = await collection(firestore, "users");
    const usersSnapshot = await getDocs(usersCollection);

    const userTests = [];
    for (const userDoc of usersSnapshot.docs) {
      const userId = userDoc.id;
      const userRef = userDoc.ref;
      const userEmail = userDoc.data().email;
      const userName = userDoc.data().displayName;
      const userTestsCollectionRef = await collection(userRef, "tests-taken");
      const userTestsSnapshot = await getDocs(userTestsCollectionRef);

      if (userTestsSnapshot.size !== 0) {
        userTestsSnapshot.docs.forEach((test) => {
          const timeString = test.data().time;
          const timeArr = timeString.split(',')

          const tdate = timeArr[0];
          const ttime = timeArr[1];

          const testInfo = {
            userId: userId,
            testId: test.id,
            userName: userName,
            userEmail: userEmail,
            date: tdate,
            time: ttime,
            forSorting: convertToUnixTime(timeString),
            name: test.data()["test-name"],
          };

          userTests.push(testInfo);
          updateCallback(testInfo); // Emit update for each test
        });
      }
    }

    userTests.sort((a, b) => b.forSorting - a.forSorting);
    return { userTests };
  } catch (error) {
    console.error("Error fetching user tests:", error);
  }
};

// Adds a new blog post
const addBlogPost = async (title, contentLink) => {
  const newBlogPost = {
    title: title,
    content: contentLink,
    createdTime: new Date().toISOString(),
  };
  try {
    const collectionReference = await collection(firestore, "blog-posts");
    await addDoc(collectionReference, newBlogPost);
    console.log("Successfully added new blog post");
  } catch (error) {
    console.log(error);
  }
};

// Retrieves blog posts ordered by the creation time
const getBlogPosts = async () => {
  try {
    const collectionReference = collection(firestore, "blog-posts");
    const querySnapshot = await getDocs(
      query(collectionReference, orderBy("date", "desc"))
    );
    const blogPosts = [];
    querySnapshot.forEach((doc) => {
      const postData = {
        id: doc.id,
        ...doc.data(),
      };
      blogPosts.push(postData);
    });
    return blogPosts;
  } catch (error) {
    console.error("Error retrieving blog posts:", error);
    return [];
  }
};

const deleteBlogPost = async (postId) => {
  try {
    const collectionReference = collection(firestore, "blog-posts");
    const q = query(collectionReference, where("__name__", "==", postId));
    const querySnapshot = await getDocs(q);

    querySnapshot.forEach(async (doc) => {
      await deleteDoc(doc.ref);
    });

  } catch (error) {
    console.error("Error deleting blog post:", error);
  }
};



// Retrieves the download count for books
const getDownloadsCount = async () => {
  try {
    const collectionRef = await collection(firestore, "books-downloads");
    const documentRef = await getDocs(collectionRef);
    return documentRef.docs[0].data();
  } catch (error) {
    console.log("Something went wrong: try again");
  }
};

// admin login

const adminLogin = async () => {
  try {
    const adminRef = await collection(firestore, "admin-credentials");
    const adminLog = await getDocs(adminRef);
    return adminLog.docs[0].data();
  } catch (error) {
    console.log("failed to fetch admin passwords");
  }
};

//Code to generate voucher PDF. Generated by Claude AI. Direct generation using PDF Syntax.
const generateVoucherPDF = (groupedData,groupName) => {
    const codeList = groupedData[groupName].map(item => item.code);
    const codesPerPage = 60; // Adjust this number based on your needs
    const pageCount = Math.ceil(codeList.length / codesPerPage);

    let pdfContent = `%PDF-1.7\n`;
    let xref = [`0000000000 65535 f\n`];
    let objectNumber = 1;

    // Catalog
    const catalogObj = `${objectNumber} 0 obj\n<</Type /Catalog /Pages ${objectNumber + 1} 0 R>>\nendobj\n`;
    xref.push(pdfContent.length.toString().padStart(10, '0') + ' 00000 n\n');
    pdfContent += catalogObj;
    objectNumber++;

    // Pages
    const pagesObj = `${objectNumber} 0 obj\n<</Type /Pages /Kids [${Array(pageCount).fill().map((_, i) => `${objectNumber + 1 + i * 2} 0 R`).join(' ')}] /Count ${pageCount}>>\nendobj\n`;
    xref.push(pdfContent.length.toString().padStart(10, '0') + ' 00000 n\n');
    pdfContent += pagesObj;
    objectNumber++;

    // Font
    const fontObj = `${objectNumber + pageCount * 2} 0 obj\n<</Type /Font /Subtype /Type1 /BaseFont /Helvetica>>\nendobj\n`;
    const boldFontObj = `${objectNumber + pageCount * 2 + 1} 0 obj\n<</Type /Font /Subtype /Type1 /BaseFont /Helvetica-Bold>>\nendobj\n`;
    
    // Helper function to draw a table
    const drawTable = (x, y, width, height, rows, columns) => {
      let tableContent = `${x} ${y} m\n`;
      for (let i = 0; i <= rows; i++) {
        tableContent += `${x} ${y - i * (height / rows)} m ${x + width} ${y - i * (height / rows)} l S\n`;
      }
      for (let j = 0; j <= columns; j++) {
        tableContent += `${x + j * (width / columns)} ${y} m ${x + j * (width / columns)} ${y - height} l S\n`;
      }
      return tableContent;
    };

    // Generate each page
    for (let i = 0; i < pageCount; i++) {
      const pageStart = i * codesPerPage;
      const pageEnd = Math.min((i + 1) * codesPerPage, codeList.length);
      const pageContent = codeList.slice(pageStart, pageEnd);
      
      let contentStream = `BT\n/F2 12 Tf\n50 800 Td\n(Voucher Code List - Page ${i + 1} of ${pageCount}) Tj\nET\n`;

      // Add table on the first page
      if (i === 0) {
        contentStream += drawTable(50, 780, 495, 100, 5, 2);

        // Add information to the table
        contentStream += `BT\n/F2 10 Tf\n`;
        const infoItems = [
          { label: 'Valid From:', value: groupedData[groupName][0].validFrom },
          { label: 'Valid Till:', value: groupedData[groupName][0].validTill },
          { label: 'Code Group Name:', value: groupName },
          { label: 'Code Group ID:', value: groupedData[groupName][0].groupId },
          { label: 'Total Codes:', value: codeList.length.toString() }
        ];

        infoItems.forEach((item, index) => {
          const y = 765 - index * 20;
          contentStream += `1 0 0 1 55 ${y} Tm (${item.label}) Tj\n`;
          contentStream += `1 0 0 1 300 ${y} Tm (${item.value}) Tj\n`;
        });

        contentStream += `ET\n`;
      }

      // Draw codes table
      const tableY = i === 0 ? 620 : 780;
      const tableHeight = i === 0 ? 560 : 720;
      const rows = Math.ceil(pageContent.length / 2);
      contentStream += drawTable(50, tableY, 495, tableHeight, rows, 2);

      // Add codes to the table
      contentStream += `BT\n/F1 9 Tf\n`;
      for (let j = 0; j < pageContent.length; j++) {
        const col = j % 2;
        const row = Math.floor(j / 2);
        const x = 55 + col * 247.5;
        const y = tableY - 15 - row * (tableHeight / rows);
        contentStream += `1 0 0 1 ${x} ${y} Tm (${pageStart + j + 1}. ${pageContent[j]}) Tj\n`;
      }
      contentStream += `ET\n`;
      
      const pageObj = `${objectNumber} 0 obj\n<</Type /Page /Parent 2 0 R /Resources <</Font <</F1 ${objectNumber + pageCount * 2} 0 R /F2 ${objectNumber + pageCount * 2 + 1} 0 R>>>> /MediaBox [0 0 595 842] /Contents ${objectNumber + 1} 0 R>>\nendobj\n`;
      xref.push(pdfContent.length.toString().padStart(10, '0') + ' 00000 n\n');
      pdfContent += pageObj;
      objectNumber++;

      const contentObj = `${objectNumber} 0 obj\n<</Length ${contentStream.length}>>\nstream\n${contentStream}\nendstream\nendobj\n`;
      xref.push(pdfContent.length.toString().padStart(10, '0') + ' 00000 n\n');
      pdfContent += contentObj;
      objectNumber++;
    }

    xref.push(pdfContent.length.toString().padStart(10, '0') + ' 00000 n\n');
    pdfContent += fontObj;
    xref.push(pdfContent.length.toString().padStart(10, '0') + ' 00000 n\n');
    pdfContent += boldFontObj;

    const xrefOffset = pdfContent.length;
    pdfContent += `xref\n0 ${xref.length}\n${xref.join('')}`;
    pdfContent += `trailer\n<</Size ${xref.length} /Root 1 0 R>>\nstartxref\n${xrefOffset}\n%%EOF`;

    const blob = new Blob([pdfContent], { type: 'application/pdf' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `${groupName}_voucher_list.pdf`;
    link.click();
};


export {
  fetchAllUsers,
  fetchUserTests,
  //generateCoupon,
  addBlogPost,
  getBlogPosts,
  getDownloadsCount,
  adminLogin,
  getAllCoupons,
  deleteCoupon,
  generateVoucher,
  getAllVouchers,
  deleteVoucher,
  deleteBlogPost,
  fetchAllAppointments,
  fetchAllTransactions,
  fetchAllLogs,
  generateBulkVouchers,
  deleteVoucherGroup,
  generateVoucherPDF,


  updateCouponsWithGroup,
  prepareBulkVouchers
};
