import React from "react";
import { TfiMenu } from "react-icons/tfi";

function Header({ OpenSidebar }) {
  return (
    <header className="a_header">
      <div className="a_menu-icon">
        <TfiMenu className="icon" onClick={OpenSidebar} />
      </div>
      <div className="a_header-right">
        <h2>Admin</h2>
      </div>
    </header>
  );
}

export default Header;
