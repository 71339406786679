import { prepareBulkVouchers, updateCouponsWithGroup } from "../../../FireBase/adminServices"

const ServicesTest = () => {

    const onclickHandler = async () => {
        await prepareBulkVouchers(10)
    }


    return (
        <>
        <button onClick={onclickHandler}>TESTING</button>
        </>
    )
}

export {ServicesTest}