import React, { useEffect, useState } from "react";
import { IoIosAddCircle } from "react-icons/io";
import { MdDelete } from "react-icons/md";
import CreateCoupon from "./CreateCoupon.jsx";
import { FaSearch } from "react-icons/fa";
import {
  deleteCoupon,
  deleteVoucherGroup,
  generateVoucherPDF,
  getAllCoupons,
} from "../../../FireBase/adminServices.js";

function Coupon() {
  const [showCreateVoucher, setShowCreateVoucher] = useState(false);
  const [voucherData, setVoucherData] = useState(null);
  const [collapsedGroups, setCollapsedGroups] = useState({});
  const [search, setSearch] = useState("");

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const data = await getAllCoupons();
      const initialCollapsedState = data.reduce((acc, item) => {
        acc[item.groupName] = true;
        return acc;
      }, {});

      setVoucherData(data);
      setCollapsedGroups(initialCollapsedState);
    } catch (error) {
      console.log("Error Fetching data:", error);
    }
  };

  const toggleCreateVoucher = () => {
    setShowCreateVoucher(!showCreateVoucher);
  };

  const handleCloseCreateVoucher = () => {
    setShowCreateVoucher(false);
  };

  const handleVoucherGenerate = () => {
    setShowCreateVoucher(false);
    fetchData();
  };

  const handleDeleteVoucher = async (code) => {
    try {
      let confirmation = window.confirm(
        "Are you sure you want to delete voucher: " + code
      );
      if (!confirmation) return;
      await deleteCoupon(code);
      await fetchData();
    } catch (error) {
      console.error("Error deleting Coupon:", error);
    }
  };

  const handleDeleteVoucherGroup = async (groupName, groupId) => {
    try {
      let confirmation = window.confirm(
        "Are you sure you want to delete voucher group: " + groupName
      );
      if (!confirmation) return;
      await deleteVoucherGroup(groupId);
      await fetchData();
    } catch (error) {
      console.error("Error deleting Coupon:", error);
    }
  };

  const toggleGroupCollapse = (group) => {
    setCollapsedGroups((prev) => ({
      ...prev,
      [group]: !prev[group],
    }));
  };

  const groupBy = (array, key) => {
    return array.reduce((result, currentValue) => {
      (result[currentValue[key]] = result[currentValue[key]] || []).push(
        currentValue
      );
      return result;
    }, {});
  };

  const isExpired = (validTill) => {
    const today = new Date();
    const expirationDate = new Date(validTill);
    return today > expirationDate;
  };

  const groupedData = voucherData
    ? groupBy(voucherData, "groupName")
    : {};

  const activeGroups = Object.entries(groupedData).filter(([_, coupons]) =>
    coupons.some((coupon) => !isExpired(coupon.validTill))
  );

  const expiredGroups = Object.entries(groupedData).filter(([_, coupons]) =>
    coupons.every((coupon) => isExpired(coupon.validTill))
  );

  const headerStyle = {
    backgroundColor: "#f2f2f2",
    color: "#333",
    fontWeight: "bold",
    border: "1px solid #ccc",
    padding: "10px",
  };

  const cellStyle = {
    border: "1px solid #ccc",
    padding: "8px",
    color: "black",
    justify: "center",
    align: "center",
    textAlign: "center"
  };

  const renderGroupTable = (groups, title) => (
    <>
      <h2 style={{ margin: "20px 0", color: "#333" }}>{title}</h2>
      <table id="group-table" style={{ width: "100%", borderCollapse: "collapse", marginBottom: "30px" }}>
        <thead className="tablehead">
          <tr>
            <th style={headerStyle}>Group Name</th>
            <th style={headerStyle}>Expand/Collapse</th>
            <th style={headerStyle}>No. of Vouchers</th>
            <th style={headerStyle}>Download PDF</th>
            <th style={headerStyle}>Delete Group</th>
          </tr>
        </thead>
        <tbody>
          {groups.map(([group, coupons]) => (
            <React.Fragment key={group}>
              <tr>
                <td style={cellStyle}>{group}</td>
                <td style={cellStyle}>
                  <button style={{ padding: 10 }} onClick={() => toggleGroupCollapse(group)}>
                    {collapsedGroups[group] ? "Expand" : "Collapse"}
                  </button>
                </td>
                <td style={cellStyle}>{coupons.length}</td>
                <td style={cellStyle}>
                  <button style={{ padding: 10, backgroundColor: "#108c20", color: "white" }} onClick={() => generateVoucherPDF(groupedData, group)}>
                    Download Codes
                  </button>
                </td>
                <td style={cellStyle}>
                  <button style={{ padding: 10, backgroundColor: "#f74f4f", color: "white" }} onClick={() => handleDeleteVoucherGroup(group, coupons[0].groupId)}>
                    Delete All
                  </button>
                </td>
              </tr>
              {!collapsedGroups[group] && (
                <tr>
                  <td colSpan="5" style={cellStyle}>
                    <table id="users" style={{ width: "100%", borderCollapse: "collapse" }}>
                      <thead className="tablehead">
                        <tr>
                          <th>No</th>
                          <th>Created On</th>
                          <th>Voucher Code</th>
                          <th>Limit</th>
                          <th>Redeemed</th>
                          <th>Credit Per Voucher</th>
                          <th>Valid Till</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {coupons
                          .filter((item) => {
                            try {
                              return search.toLowerCase() === ""
                                ? item
                                : item.code.toLowerCase().includes(search.toLowerCase());
                            } catch (error) {
                              console.error(error);
                              return false;
                            }
                          })
                          .map((item, index) => (
                            <tr key={item.code}>
                              <td style={cellStyle}>{index + 1}</td>
                              <td style={cellStyle}>{new Date(item.validFrom).toLocaleDateString()}</td>
                              <td style={cellStyle}>{item.code}</td>
                              <td style={cellStyle}>{item.limit}</td>
                              <td style={cellStyle}>{item.redeemed}</td>
                              <td style={cellStyle}>{item.credit}</td>
                              <td style={cellStyle}>{new Date(item.validTill).toLocaleDateString()}</td>
                              <td style={cellStyle}>
                                <MdDelete
                                  className="action"
                                  onClick={() => handleDeleteVoucher(item.code)}
                                />
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </>
  );

  return voucherData != null ? (
    <main id="voucher" className="a_main-container">
      <div className="main-title text-color">
        <h3>Voucher code</h3>
      </div>
      <div id="voucheradd">
        <button onClick={toggleCreateVoucher}>
          <IoIosAddCircle />
          &nbsp; Generate Voucher Code
        </button>
      </div>
      {showCreateVoucher && (
        <CreateCoupon
          onClose={handleCloseCreateVoucher}
          onGenerate={handleVoucherGenerate}
        />
      )}
      <div className="pt">
        <form className="search-bar">
          <FaSearch className="icon" />
          <input
            type="text"
            placeholder="Search Here.."
            onChange={(e) => setSearch(e.target.value)}
          />
        </form>
      </div>
      <div className="a_table">
        {renderGroupTable(activeGroups, "Active Voucher Groups")}
        {renderGroupTable(expiredGroups, "Expired Voucher Groups")}
      </div>
    </main>
  ) : (
    <div>
      <span className="loader"></span>
    </div>
  );
}

export default Coupon;