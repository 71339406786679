import { useEffect, useState } from "react";
import { fetchAllAppointments } from "../../../FireBase/adminServices";
import { FaSearch } from "react-icons/fa";

const Appointments = () =>{
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchAllAppointments();
        setuserData(data);
        console.log(data)
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  },[]);

  const filterAppointments = (appointments) => {
    return appointments.filter((item) => {
      try {
        return search.toLowerCase() === undefined
          ? item
          : item.fullName.toLowerCase().startsWith(search);
      } catch (error) {
        return false;
      }
    });
  };

  function tConvert (time) {
  // Check correct time format and split into components
  time = time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

  if (time.length > 1) { // If time format correct
    time = time.slice (1);  // Remove full string match value
    time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
    time[0] = +time[0] % 12 || 12; // Adjust hours
  }
  return time.join (''); // return adjusted time or original string
  }

  const [userData, setuserData] = useState(null);

  const [search, setSearch] = useState("");
  let count = 1;
  return userData != null ? (
    <main className="a_main-container">
      <div className="pt">
        <form className="search-bar">
          <FaSearch className="icon" />
          <input
            type="text"
            placeholder="Search Here.."
            onChange={(e) => setSearch(e.target.value.toLowerCase())}
          ></input>
        </form>
      </div>
         <div className="a_table">
      <table id="users">
        <thead className="tablehead">
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Email</th>
            <th>Phone</th>
            <th>Date</th>
            <th>Time</th>
            <th>Online/Offline</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan="7">
              <h3>Upcoming Appointments</h3>
            </td>
          </tr>
          {filterAppointments(userData.upcoming).map((item) => (
            <tr key={item.uid}>
              <td>{count++}</td>
              <td>{item.fullName}</td>
              <td>{item.email}</td>
              <td>{item.phoneNumber}</td>
              <td>{item.selectedDate.split('T')[0]}</td>
              <td>{tConvert(item.selectedDate.split('T')[1])}</td>
              <td>{item.appointmentType.toUpperCase()}</td>
            </tr>
          ))}

          <tr>
            <td colSpan="7">
              <h3>Completed Appointments</h3>
            </td>
          </tr>
          {filterAppointments(userData.completed).map((item) => (
            <tr key={item.uid}>
              <td>{count++}</td>
              <td>{item.fullName}</td>
              <td>{item.email}</td>
              <td>{item.phoneNumber}</td>
              <td>{item.selectedDate.split('T')[0]}</td>
              <td>{tConvert(item.selectedDate.split('T')[1])}</td>
              <td>{item.appointmentType.toUpperCase()}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    </main>
  ) : (
    <div>
      <span class="loader"></span>
    </div>
  );
}


export {Appointments};