import React, { useState, useEffect } from "react";
import { BsCalendar, BsPeopleFill } from "react-icons/bs";
import { MdOutlinePaid } from "react-icons/md";
import { SiTestcafe } from "react-icons/si";
import { fetchAllAppointments, fetchAllUsers, fetchAllTransactions } from "../../FireBase/adminServices";
import { FaSearch } from "react-icons/fa";

function Home() {
  const [userData, setUserData] = useState(null);
  const [appointmentsData, setAppointmentsData] = useState({ upcoming: [], completed: [] });
  const [transactionsData, setTransactionsData] = useState([]);
  const [search, setSearch] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const users = await fetchAllUsers();
        const appointments = await fetchAllAppointments();
        const transactions = await fetchAllTransactions();

        setUserData(users);
        setAppointmentsData(appointments);
        setTransactionsData(transactions);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  let count = 1;
  const cardData = [
    {
      title: "Users",
      value: userData != null ? userData.paidUsers + userData.freeUsers : 0,
      icon: <BsPeopleFill className="a_card_icon" />,
    },
    {
      title: "Tests Taken",
      value: userData != null ? userData.testTaken - 6 : 0,
      icon: <SiTestcafe className="a_card_icon" />,
    },
    {
      title: "Upcoming Appointments",
      value: appointmentsData.upcoming.length,
      icon: <BsCalendar className="a_card_icon" />,
    },
    {
      title: "Transactions Completed",
      value: transactionsData.length,
      icon: <MdOutlinePaid className="a_card_icon" />,
    },
  ];

  return userData != null ? (
    <main className="a_main-container">
      <div className="a_main-cards">
        {cardData.map((item) => (
          <div className="a_card" key={item.title}>
            <div className="a_card-inner">
              <h3>{item.title}</h3>
              {item.icon}
            </div>
            <h1>{item.value}</h1>
          </div>
        ))}
      </div>
      <div className="pt">
        <form className="search-bar">
          <FaSearch className="icon" />
          <input
            type="text"
            placeholder="Search Here.."
            onChange={(e) => setSearch(e.target.value)}
          ></input>
        </form>
      </div>
      <div className="a_table">
        <table id="users">
          <thead className="tablehead">
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Email</th>
              <th>Phone</th>
            </tr>
          </thead>
          <tbody>
            {userData.usersList
              .filter((item) => {
                try {
                  return search.toLowerCase() === undefined
                    ? item
                    : item.displayName.toLowerCase().startsWith(search);
                } catch (error) {
                  return error;
                }
              })
              .map((item) => (
                <tr key={item.uid}>
                  <td>{count++}</td>
                  <td>{item.displayName}</td>
                  <td>{item.email}</td>
                  <td>{item.phoneNumber}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </main>
  ) : (
    <div>
      <span className="loader"></span>
    </div>
  );
}

export default Home;
