import React, { useState } from "react";
import { generateVoucher } from "../../../FireBase/adminServices";

function CreateVoucher({ onClose, onGenerate }) {
  const [voucherCode, setVoucherCode] = useState("");
  const [quantity, setQuantity] = useState(0);

  const today = new Date();
  const formattedDate = today.toISOString().split('T')[0];

  const [discount_percentage, set_discount_percentage] = useState(0);
  const [validityStart, setValidityStart] = useState(formattedDate);
  const [validityEnd, setValidityEnd] = useState("");
  const [showPopup, setShowPopup] = useState(false);

  const handleClose = () => {
    onClose();
  };

  const handleVoucherGenerate = () => {
    const voucherData = {
      code: voucherCode,
      discount_percentage: parseInt(discount_percentage),
      validityStart: validityStart,
      validityEnd: validityEnd,
      quantity: parseInt(quantity),
    };

    // Call your Firebase function to upload voucher data
    generateVoucher(voucherData)
      .then(() => {
        console.log("Discount Coupon generated successfully");
        setShowPopup(true);
        onGenerate(); // Inform parent component about voucher generation
      })
      .catch((error) => {
        console.error("Error generating voucher:", error);
        // Handle error appropriately
      });
  };

  const handlePopupClose = () => {
    setShowPopup(false);
  };

  return (
    <div id="createVoucher">
      {showPopup && (
        <div className="popup">
          <span className="close" onClick={handlePopupClose}>
            &times;
          </span>
          Discount Coupon Generated Successfully!
        </div>
      )}
      <h2>Generate Discount Coupon</h2>
      <div>
        <form className="voucherForm">
          <div className="form-group">
            <label htmlFor="voucherCode">Discount Coupon:</label>
            <input
              type="text"
              id="voucherCode"
              placeholder="Enter Discount Coupon"
              value={voucherCode}
              onChange={(e) => setVoucherCode(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="quantity">Quantity:</label>
            <input
              type="text"
              id="quantity"
              placeholder="Enter Quantity"
              value={quantity}
              onChange={(e) => setQuantity(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="discount_percentage">Discount in %</label>
            <br/>
            <input
              type="number"
              id="discount_percentage"
              max={100}
              placeholder="Enter %"
              value={discount_percentage}
              onChange={(e) => set_discount_percentage(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="validityDate">Validity from:</label>
            <input
              type="date"
              id="validityStart"
              value={validityStart}
              onChange={(e) => setValidityStart(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="validityDate">Validity to:</label>
            <input
              type="date"
              id="validityDate"
              value={validityEnd}
              onChange={(e) => setValidityEnd(e.target.value)}
            />
          </div>
        </form>
      </div>

      <div className="voucher-Btn">
        <button className="close" onClick={handleClose}>
          Close
        </button>
        <button className="generate" onClick={handleVoucherGenerate}>
          Generate
        </button>
      </div>
    </div>
  );
}

export default CreateVoucher;
