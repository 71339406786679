import { useEffect, useState } from "react";
import { fetchAllTransactions } from "../../FireBase/adminServices";
import { FaSearch } from "react-icons/fa";

const Payments = () =>{
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchAllTransactions();
        setuserData(data);
        console.log(data)
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  },[]);

  const [userData, setuserData] = useState(null);

  const [search, setSearch] = useState("");
  let count = 1;
  return userData != null ? (
    <main className="a_main-container">
      <div className="pt">
        <form className="search-bar">
          <FaSearch className="icon" />
          <input
            type="text"
            placeholder="Search Here.."
            onChange={(e) => setSearch(e.target.value.toLowerCase())}
          ></input>
        </form>
      </div>
      <div className="a_table">
        <table id="users">
          <thead className="tablehead">
            <tr>
              <th>ID</th>
              <th>User Name</th>
              <th>Email</th>
              <th>Selected Plan</th>
              <th>Payment Amount</th>
              <th>Payment Currency</th>
            </tr>
          </thead>
          <tbody>
            {userData.filter((item) => {
                try {
                  return search.toLowerCase() === undefined
                    ? item
                    : item.userName.toLowerCase().startsWith(search);
                } catch (error) {
                  return error;
                }
              }).map((item) => (
              <tr key={item.userId}>
                <td>{count++}</td>
                <td>{item.userName}</td>
                <td>{item.userEmail}</td>
                <td>{item.selectedPlan}</td>
                <td>{item.paymentAmount}</td>
                <td>{item.paymentCurrency}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </main>
  ) : (
    <div>
      <span class="loader"></span>
    </div>
  );

}


export {Payments};