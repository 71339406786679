import React, { Component,useEffect, useState } from "react";
import "./admin.css";
import admin from "./admin.jpeg";
import AdminDashboard from "../AdminDashboard/Main";
import { adminLogin } from "../../FireBase/adminServices";
import { signInWithEmailAndPassword } from "firebase/auth";
import { getCurrentUser, loginWithEmailAndPassword } from "../../FireBase/authServices";

const AdminLogin = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [inputFields, setInputFields] = useState({
      username:"",
      password:""
    })

    useEffect(()=>{
    async function getLoginState (){

      const isLoggedIn = await getCurrentUser()

      if(isLoggedIn)
      setIsLoggedIn(true)
      setIsLoading(false)

    }

    getLoginState()

    },[])


  const handleInputChange = (event) => {
      const { name, value } = event.target;
      setInputFields({
        ...inputFields,
        [name]: value,
      });
    };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const loginStatus = await loginWithEmailAndPassword(inputFields.username,inputFields.password)
      if (
        loginStatus.status === 'success'
      ) {
        setIsLoggedIn(true)
      } else {
        alert("Invalid username or password");
      }
    } catch (error) {
      console.log(error);
    }
  };

    if(isLoading){
      return <div>LOADING....</div>
    }

    if (isLoggedIn) {
      return <AdminDashboard />;
    }

    return (
      <div className="admin">
        <div className="admin-main">
          <div className="admin-left">
            <img src={admin} alt="login logo" />
          </div>
          <div className="admin-right">
            <h2>Admin Login</h2>
            <form onSubmit={null}>
              <input
                type="text"
                name="username"
                placeholder="Enter username"
                value={inputFields.username}
                onChange={handleInputChange}
              />
              <br />
              <input
                type="password"
                name="password"
                placeholder="Enter Password"
                value={inputFields.password}
                onChange={handleInputChange}
              />
              <button onClick={handleSubmit}>Login</button>
            </form>
          </div>
        </div>
      </div>
    );
  
}

export default AdminLogin;
