import React, { useEffect, useState } from "react";
import { FaSearch } from "react-icons/fa";
import "./pages.css";
import { fetchUserTests } from "../../../FireBase/adminServices";
import EventEmitter from "events";

function TestTaken() {
  const [userData, setUserData] = useState([]);
  const [search, setSearch] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedTest, setSelectedTest] = useState("");
  const [initialLoading, setInitialLoading] = useState(true);
  const [progressiveLoading, setProgressiveLoading] = useState(true);

  useEffect(() => {
    const eventEmitter = new EventEmitter();

    eventEmitter.on('update', (testInfo) => {
      setUserData(prevUserData => [...prevUserData, testInfo]);
      if (initialLoading) {
        setInitialLoading(false); // Hide initial loading after receiving first batch of data
      }
    });

    const fetchData = async () => {
      try {
        await fetchUserTests((testInfo) => eventEmitter.emit('update', testInfo));
        setProgressiveLoading(false); // Set progressive loading to false once all data is fetched
      } catch (error) {
        console.error("Error fetching data:", error);
        setInitialLoading(false);
        setProgressiveLoading(false);
      }
    };

    fetchData();

    return () => {
      eventEmitter.removeAllListeners();
    };
  }, [initialLoading]);

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  const handleTestChange = (event) => {
    setSelectedTest(event.target.value);
  };

  const filteredTests = userData
    ? userData.filter((item) => {
        const matchesSearch =
          item.userName.toLowerCase().startsWith(search.toLowerCase()) ||
          item.userEmail.toLowerCase().startsWith(search.toLowerCase());
        const matchesDate = !selectedDate || item.date === selectedDate;
        const matchesTest =
          !selectedTest ||
          item.name.toLowerCase() === selectedTest.toLowerCase();
        return matchesSearch && matchesDate && matchesTest;
      })
    : [];

  let count = 1;
  return (
    <main id="test" className="a_main-container">
      <div className="main-title text-color">
        <h3>Tests Taken By Users</h3>
      </div>
      <div className="filters">
        <div className="search-bar">
          <FaSearch className="icon" />
          <input
            type="text"
            placeholder="Search Here.."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <div className="date-filter pt">
          <input
            type="date"
            value={selectedDate}
            onChange={handleDateChange}
            placeholder="Select Date"
          />
        </div>
        <div className="test-filter">
          <select value={selectedTest} onChange={handleTestChange}>
            <option value="">All Tests</option>
            <option value="VARK Test">Memory Test</option>
            <option value="vark">VARK Test</option>
            <option value="iq">IQ Test</option>
            <option value="english">English Test</option>
          </select>
        </div>
      </div>
      <div className="a_table">
        {initialLoading && (
          <div className="loader-container">
            <span className="loader"></span>
          </div>
        )}
        <table id="users">
          <thead className="tablehead">
            <tr>
              <th>ID</th>
              <th>Date</th>
              <th>Time</th>
              <th>Name</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Test</th>
              <th>Report</th>
            </tr>
          </thead>
          <tbody>
            {filteredTests.map((item) => (
              <tr key={item.id}>
                <td>{count++}</td>
                <td>{item.date}</td>
                <td>{item.time}</td>
                <td>{item.userName}</td>
                <td>{item.userEmail}</td>
                <td>Available in v2</td>
                <td>{item.name}</td>
                <td className="report-button">
                  <button>View</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {progressiveLoading && (
          <div className="loader-container">
            <span className="loader"></span>
          </div>
        )}
      </div>
    </main>
  );
}

export default TestTaken;
