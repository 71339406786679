import { useState } from "react";
import "./dashboard.css";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Dashboard from "./Dashboard";
import Blog from "./Pages/blog/Blog";
import Coupon from "./Pages/Coupon";
import Test from "./Pages/TestTaken";
import Voucher from "./Pages/VoucherCode";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Appointments } from "./Pages/Appointments";
import { Payments } from "./Payments";
import { Logs } from "./Logs";
import { ServicesTest } from "./Pages/ServicesTest";

function App() {
  const [openSidebarToggle, setOpenSidebarToggle] = useState(false);

  const OpenSidebar = () => {
    setOpenSidebarToggle(!openSidebarToggle);
  };

  return (
    <BrowserRouter>
      <div className="a_grid-container">
        <Header OpenSidebar={OpenSidebar} />
        <Sidebar
          openSidebarToggle={openSidebarToggle}
          OpenSidebar={OpenSidebar}
        />
        <Routes>
          <Route path="" element={<Dashboard />}></Route>
          <Route path="/test" element={<Test />}></Route>
          <Route path="/voucher" element={<Coupon />}></Route>
          <Route path="/coupon" element={<Voucher />}></Route>
          <Route path="/blog" element={<Blog />}></Route>
          <Route path = '/appointments' element={<Appointments/>}></Route>
          <Route path = '/payments' element={<Payments/>}/>
          <Route path = '/logs' element={<Logs/>}/>
          <Route path='/services-test' element={<ServicesTest/>}/>
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
