import Login from "./Components/Admin/AdminLogin/Admin";
import DashBoard from "./Components/Admin/AdminDashboard/Main";
function App() {
  return (
    <>
      <Login />
    </>
  );
}

export default App;
