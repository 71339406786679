import React from "react";
import { BsGrid1X2Fill } from "react-icons/bs";
import { SiTestcafe } from "react-icons/si";
import { MdAttachMoney, MdCalendarToday, MdMoney, MdOutlineDiscount } from "react-icons/md";
import { TbBookDownload } from "react-icons/tb";
import { MdOutlineMessage } from "react-icons/md";
import { BiSolidDiscount } from "react-icons/bi";
import Logo from "./logo.png";
import { Link } from "react-router-dom";
import { IoLocation, IoLocationOutline, IoLogOut } from "react-icons/io5";
import { logout } from "../../FireBase/authServices";

function Sidebar({ openSidebarToggle, OpenSidebar }) {
  const handleLogout = async () => {
    const confirmLogout = window.confirm("Are you sure you want to log out?");
    // Redirect the user to the login page
    if (confirmLogout) {
      await logout()
      window.location.href = "/";
    }
  };

  const sidebarData = [
    {
      title: "Dashboard",
      icon: <BsGrid1X2Fill className="a_icon" />,
      link: "/",
    },
    {
      title: "Tests Taken",
      icon: <SiTestcafe className="icon" />,
      link: "/test",
    },
    {
      title: "Coupon",
      icon: <BiSolidDiscount className="icon" />,
      link: "/coupon",
    },
    {
      title: "Voucher",
      icon: <MdOutlineDiscount className="a_icon" />,
      link: "/voucher",
    },
    {
      title: "Blog",
      icon: <MdOutlineMessage className="a_icon" />,
      link: "/blog",
    },    
    {
      title: "Appointments",
      icon: <MdCalendarToday className="a_icon" />,
      link: "/appointments",
    },    
    {
      title: "Payments",
      icon: <MdAttachMoney className="a_icon" />,
      link: "/payments",
    },
    {
      title: "Logs",
      icon: <IoLocationOutline className="a_icon" />,
      link: "/logs",
    },
    {
      title: "Log Out",
      icon: <IoLogOut className="a_icon" />,
      link: "/", // Assuming the login page route is "/login"
      onClick: handleLogout,
    },
  ];

  return (
    <aside
      id="sidebar"
      className={openSidebarToggle ? "a_sidebar-responsive" : ""}
    >
      <div className="a_sidebar-title">
        <div className="a_sidebar-brand">
          {/* <img className="a_logo" src={Logo} height={50} width={50} alt="logo" /> */}
          BranchSelector
        </div>
        <span className="a_icon a_close_icon" onClick={OpenSidebar}>
          X
        </span>
      </div>

      {sidebarData.map((item, index) => (
        <ul key={index} className="a_sidebar-list">
          <li
            className="a_sidebar-list-item"
            onClick={item.onClick || OpenSidebar}
          >
            <Link to={item.link}>
              {item.icon} {item.title}
            </Link>
          </li>
        </ul>
      ))}
    </aside>
  );
}

export default Sidebar;
