import React, { useEffect, useState } from "react";
import { IoIosAddCircle } from "react-icons/io";
import { MdDelete } from "react-icons/md";
import CreateVoucher from "./CreateVoucher.jsx";
import { FaSearch } from "react-icons/fa";
import {
  deleteVoucher,
  getAllVouchers,
} from "../../../FireBase/adminServices.js";

function VoucherCode() {
  const [showCreateVoucher, setShowCreateVoucher] = useState(false);
  const [voucherData, setVoucherData] = useState(null);
  const [search, setSearch] = useState("");

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const data = await getAllVouchers();
      setVoucherData(data);
    } catch (error) {
      console.log("Error Fetching data:", error);
    }
  };

  const toggleCreateVoucher = () => {
    setShowCreateVoucher(!showCreateVoucher);
  };

  const handleCloseCreateVoucher = () => {
    setShowCreateVoucher(false);
  };

  const handleVoucherGenerate = () => {
    setShowCreateVoucher(false);
    fetchData();
  };

  const handleDeleteVoucher = async (code) => {
    try {
      let response = window.confirm("Are you sure you want to delete discount coupon: " + code);
      if (!response) return;
      await deleteVoucher(code);
      fetchData();
    } catch (error) {
      console.error("Error deleting discount coupon:", error);
    }
  };

  const isExpired = (validTill) => {
    const today = new Date();
    const expirationDate = new Date(validTill);
    return today > expirationDate;
  };

  const renderVoucherTable = (vouchers, title) => (
    <>
      <h2 style={{ margin: "20px 0", color: "#333" }}>{title}</h2>
      <table id="users" style={{ width: "100%", borderCollapse: "collapse", marginBottom: "30px" }}>
        <thead className="tablehead">
          <tr>
            <th>No</th>
            <th>Created On</th>
            <th>Discount Code</th>
            <th>Limit</th>
            <th>Redeemed</th>
            <th>Discount %</th>
            <th>Valid Till</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {vouchers
            .filter((item) => {
              try {
                return search.toLowerCase() === ""
                  ? item
                  : item.code.toLowerCase().includes(search.toLowerCase());
              } catch (error) {
                console.error(error);
                return false;
              }
            })
            .map((item, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{new Date(item.validFrom).toLocaleDateString()}</td>
                <td>{item.code}</td>
                <td>{item.limit}</td>
                <td>{item.redeemed}</td>
                <td>{item.discountPercentage}%</td>
                <td>{new Date(item.validTill).toLocaleDateString()}</td>
                <td>
                  <MdDelete
                    className="action"
                    onClick={() => handleDeleteVoucher(item.code)}
                  />
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </>
  );

  const activeVouchers = voucherData ? voucherData.filter(voucher => !isExpired(voucher.validTill)) : [];
  const expiredVouchers = voucherData ? voucherData.filter(voucher => isExpired(voucher.validTill)) : [];

  return voucherData != null ? (
    <main id="voucher" className="a_main-container">
      <div className="main-title text-color">
        <h3>Discount Coupon code</h3>
      </div>
      <div id="voucheradd">
        <button onClick={toggleCreateVoucher}>
          <IoIosAddCircle />
          &nbsp; Generate Discount Coupon 
        </button>
      </div>
      {showCreateVoucher && (
        <CreateVoucher
          onClose={handleCloseCreateVoucher}
          onGenerate={handleVoucherGenerate}
        />
      )}
      <div className="pt"> 
        <form className="search-bar">
          <FaSearch className="icon" />
          <input
            type="text"
            placeholder="Search Here.."
            onChange={(e) => setSearch(e.target.value)}
          />
        </form>
      </div>
      <div className="a_table">
        {renderVoucherTable(activeVouchers, "Active Discount Coupons")}
        {renderVoucherTable(expiredVouchers, "Expired Discount Coupons")}
      </div>
    </main>
  ) : (
    <div>
      <span className="loader"></span>
    </div>
  );
}

export default VoucherCode;