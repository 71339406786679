import React from 'react';

const LogsModal = ({ log, onClose }) => {
  if (!log) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">

        <h2>Log Details</h2>
        <div className="log-details">
          <p><strong>Event:</strong> {log.EVENT}</p>
          <p><strong>Date:</strong> {log.USER_INFORMATION.DATE_TIME}</p>
          <p><strong>Profile Name: </strong> {log.USER_INFORMATION.PROFILE_NAME}</p>
          <p><strong>User ID: </strong> {log.USER_INFORMATION.USER_ID}</p>
          <p><strong>IP Address:</strong> {log.USER_INFORMATION.IP_ADDRESS}</p>
          <p><strong>User Agent:</strong> {log.USER_INFORMATION.USER_AGENT}</p>
          <h3>ASN Information</h3>
          <p><strong>ASN:</strong> {log.ASN_INFORMATION.ASN}</p>
          <p><strong>Country Code:</strong> {log.ASN_INFORMATION.ASN_COUNTRY_CODE}</p>
          <p><strong>Description:</strong> {log.ASN_INFORMATION.ASN_DESCRIPTION}</p>
          <p><strong>Registry:</strong> {log.ASN_INFORMATION.ASN_REGISTRY}</p>
          <p><strong>Network Name:</strong> {log.ASN_INFORMATION.NETWORK_NAME}</p>
          <h3>Geo Information</h3>
          <p><strong>City:</strong> {log.GEO_INFORMATION.CITY}</p>
          <p><strong>Region:</strong> {log.GEO_INFORMATION.REGION}</p>
          <p><strong>Country:</strong> {log.GEO_INFORMATION.COUNTRY}</p>
          <p><strong>Continent:</strong> {log.GEO_INFORMATION.CONTINENT}</p>
          <p><strong>Latitude:</strong> {log.GEO_INFORMATION.LATITUDE}</p>
          <p><strong>Longitude:</strong> {log.GEO_INFORMATION.LONGITUDE}</p>
          <p><strong>Timezone:</strong> {log.GEO_INFORMATION.TIMEZONE}</p>
        </div>
        <button className="close-button" onClick={onClose}>
            Close
        </button>
      </div>
    </div>
  );
};

export default LogsModal;